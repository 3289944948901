import DynamicMenuService from '@/services/shared/dynamicMenu/DynamicMenuService';
import GetSeller from '@/services/sellers/GetSeller';
import { ServiceStatus } from '@/services/sellers/types';
import SignIn from '@/services/users/authentication/SignIn';
import signOut from '@/services/users/authentication/SignOut';
import sessionDetails from '@/services/users/authentication/SessionDetails';
import { globalBus } from '@/infrastructure/plugins/globalBus';
import { SignInInfo, AuthState, User, SignOut, AuthContext, AuthActions, AuthMutations, AuthGetters } from '@/store/types';

const initialState: AuthState = {
    user: {
        logged: false,
        sellerId: '',
        seller: {
            externalId: '',
            name: '',
            createdAt: '',
            physicalAddress: {
                countryCode: null
            },
            locale: ''
        },
        dynamicMenu: [],
        activeServices: []
    },
    isLoginOut: false
};

export const actions: AuthActions = {
    async signIn({ commit }: AuthContext, signInInfo: SignInInfo) {
        await SignIn().execute(signInInfo);

        const userData: User = {
            sellerId: '',
            logged: true,
            seller: {
                externalId: '',
                name: '',
                createdAt: '',
                physicalAddress: {
                    countryCode: null
                },
                locale: ''
            },
            dynamicMenu: [],
            activeServices: []
        };

        commit('CURRENT_USER', userData);
    },

    async sessionDetails({ commit }: AuthContext) {
        const { sellerId } = await sessionDetails().execute();

        const [dynamicMenuData, sellerData] = await Promise.all([DynamicMenuService().execute(sellerId), GetSeller().execute(sellerId)]);

        const activeServices = sellerData.services
            .filter((service) => service.status === ServiceStatus.SUBSCRIBED)
            .map((service) => service.code);

        const userData: User = {
            sellerId,
            logged: true,
            seller: {
                externalId: sellerData.externalId,
                name: sellerData.name,
                createdAt: sellerData.createdAt,
                physicalAddress: sellerData.physicalAddress,
                locale: sellerData.locale.code
            },
            dynamicMenu: dynamicMenuData,
            activeServices
        };

        commit('CURRENT_USER', userData);
    },

    async signOut({ commit, state }: AuthContext, { showError = true }: SignOut) {
        // There can be multiple request trying to login out, so we manage to log out once at a time.
        if (state.isLoginOut) {
            return;
        }

        commit('SET_IS_LOGIN_OUT', true);
        // Notify app to stop intervals
        globalBus.emit('auth/logout');

        await signOut().execute();

        commit('REMOVE_CURRENT_USER');

        // Notify app to redirect to login page
        globalBus.emit('auth/redirect-login');

        if (showError) {
            globalBus.emit('error', { message: 'invalid_user_session_error_message' });
        }

        commit('SET_IS_LOGIN_OUT', false);
    }
};

export const mutations: AuthMutations = {
    CURRENT_USER(state: AuthState, userData: User) {
        state.user = userData;
    },
    REMOVE_CURRENT_USER(state: AuthState) {
        state.user = {
            logged: false,
            sellerId: '',
            seller: {
                externalId: '',
                name: '',
                createdAt: '',
                physicalAddress: {
                    countryCode: null
                },
                locale: ''
            },
            dynamicMenu: [],
            activeServices: []
        };
    },
    SET_IS_LOGIN_OUT(state: AuthState, isLoginOut: boolean) {
        state.isLoginOut = isLoginOut;
    }
};

export const getters: AuthGetters = {
    isUserLogged: (state: AuthState) => state.user.logged,
    hasMenuData: (state: AuthState) => state.user.dynamicMenu.length && state.user.seller.name !== null,
    getUser: (state: AuthState) => state.user,
    getActiveServices: (state: AuthState) => state.user.activeServices
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters
};
